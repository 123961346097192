<template>
    <div>
        <v-container class="py-10">
            <v-row>
                <v-col md="12" class="d-flex  pl-5">
                    <v-row>
                        <v-col md="2" v-if="returnAbility('final_grade:student:store')" >
                            <genFinalResults @Submitted="fetchData(1)" />
                        </v-col>
                       <v-col md="2" class="px-15" v-if="returnAbility('final_grade:student:delete')">
                        <deleteResults :is-icon="false" @record-deleted="clearFilter"  path="final-grade-student" name="Delete Final Grade" :params="filters" />
                       </v-col>
                    </v-row>
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('Template Results')" v-model="filters.final_grade_id"
                        endPoint="/final-grade" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('student name')" v-model="filters.student_id"
                        endPoint="/student/auto-complete" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('educitional class')" v-model="filters.edu_class_id"
                        endPoint="/edu-class" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('year')" v-model="filters.educational_year_id"
                        endPoint="/educational-year" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('class type')" v-model="filters.grade_type_id" endPoint="/grade-type" />
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('Percent')" v-model="filters.percent" type="number"></v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('Label')" v-model="filters.label"></v-text-field>
                </v-col>
                <v-col md="3"></v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="green" dark block @click="fetchData(1)">{{ $t('search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0"  v-if="returnAbility('final_grade:student:exportList')">
                    <ExportExcel end-point="/final-grade-student/export" :params="filters" />
                </v-col>
                <v-col md="12">
                    <app-base-table @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)"
                        :tableOptions="tableOptions" :enableDelete="false">
                        <template slot="editFeature" slot-scope="{ row }">
                            <ExportPdf  v-if="returnAbility('final_grade:student:pdf')" class="py-3" :is-icon=true end-point="/final-grade-student/pdf" :id="row.id" />
                            <ExportExcel  v-if="returnAbility('final_grade:student:excel')" class="py-3" :is-icon=true end-point="/final-grade-student/excel" :id="row.id" />
                            <deleteResults  v-if="returnAbility('final_grade:student:delete')" @record-deleted="fetchData(1)" class="py-2" path="final-grade-student" :id="row.id" />
                        </template>
                    </app-base-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import axios from "axios";
import genFinalResults from "./genFinalResults.vue";
import ExportExcel from "../../components/core/ExportExcel.vue";
import ExportPdf from "../../components/core/ExportPdf.vue";
import deleteResults from "../../components/core/delete.vue";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";

export default {
    components: {
        genFinalResults,
        ExportExcel,
        ExportPdf,
        deleteResults,
        AutoCompleteField,
    },
    data() {
        return {
            loading: false,
            filters: {
                grade_type_id: null,
                edu_class_id: null,
                student_id: null,
                final_grade_id: null,
                educational_year_id: null,
                label: null,
                percent: null,
            },
            tableOptions: {
                tableData: [],
                tableHeaders: ["id", "name", "Template Results", "year", "class type", "educitional class", "Percent", "Label", "settings"],
                accessTableData: [["id"], ["student_name"], ["final_grade"], ["educational_year"], ["grade_type"], ["edu_class"], ["percent"], ["label"]],
                loading: false,
                tableMeta: {
                    total: null,
                    page: null,
                },
            },
        };
    },
    methods: {
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get("/final-grade-student", { params });
            if (response.status == 200) {
                console.log(response.data.data);
                this.tableOptions.tableData = response.data.data,
                    this.tableOptions.tableMeta.total = response.data.meta.total;
                this.tableOptions.tableMeta.page = response.data.meta.current_page;
            }
            this.tableOptions.loading = false;
        },
        clearFilter() {
            this.filters.grade_type_id = undefined;
            this.filters.edu_class_id = undefined;
            this.filters.educational_year_id = undefined;
            this.filters.percent = undefined;
            this.filters.label = undefined;
            this.filters.student_id = undefined;
            this.filters.final_grade_id = undefined;
            this.fetchData(1);
        },
    },
    created() {
        
        this.fetchData(1);
    },
};
</script>

<style></style>
