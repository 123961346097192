<template>
  <v-dialog v-model="dialog" persistent width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="isNew" v-on="on" v-bind="attrs" color="green" depressed class="white--text">
       
        {{ $t("Generate Final Results") }}
      </v-btn>
      <v-icon v-if="!isNew" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        <h1>{{ isNew ? $t("Generate Final Results") : $t('edit') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <AutoCompleteField outlined :label="$t('Template Results')" v-model="structure.final_grade_id"
              endPoint="/final-grade" />
          </v-col>
          <v-col md="6">
            <AutoCompleteField :label="$t('student name')" outlined v-model="structure.student_id"
              endPoint="/student/auto-complete" />
          </v-col>
          <v-col md="6">
            <AutoCompleteField outlined :label="$t('educitional class')" v-model="structure.edu_class_id"
              endPoint="/edu-class" />
          </v-col>
          <v-col md="6">
            <AutoCompleteField outlined :label="$t('class')" v-model="structure.grade_id" endPoint="/grade" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">{{ $t("Generate")
        }}</v-btn>
        <v-btn color="blue darken-1" text @click="clearData">
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";

export default {
  components: {
    AutoCompleteField,
  },
  data() {
    return {
      btnLoading: false,
      dialog: false,
      structure: {
        edu_class_id: null,
        final_grade_id: null,
        student_id: null,
        grade_id: null,
      },
    };
  },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  methods: {
    async submit() {
      try {
        this.btnLoading = true;
        if (this.isNew) {
          await axios.post("/final-grade-student", this.structure);
          this.$Notifications(
            this.$t('add success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        } else {
          await axios.put(`/final-grade-student/${this.item.id}`, this.structure);
          this.$Notifications(
            this.$t('edit success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        }
        this.$emit("Submitted");
        this.clearData();
      } catch (err) {
      } finally {
        this.btnLoading = false;

      }
    },
    clearData() {
      this.structure.edu_class_id = null,
      this.structure.final_grade_id = null,
      this.structure.student_id = null,
      this.structure.grade_id = null;
      this.dialog = false;
    },
  },
};
</script>

<style></style>